import React, { Component } from 'react';
import { Link } from 'gatsby';

import Styles from './404-content.module.scss';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.gameHandler = this.gameHandler.bind( this );
  }

  gameHandler() {
    console.log('start');

    let canvas = this.refs.canvas;
    let ctx = canvas.getContext('2d');

    ctx.canvas.width = 400;
    ctx.canvas.heigh = 150;

    // let text = [
    //    '404',
    //    50,
    //    90
    // ];

    // ctx.font = '50px serif';
    // ctx.fillStyle = 'red';
    // ctx.globalAlpha = 0.5;
    // ctx.fillText(...text);
  }

  render() {

    return(
      <section className={Styles.section}>
        {/* <canvas 
          ref="canvas"
          className={Styles.canvas}
          onClick={this.gameHandler}
        /> */}

        <h1>404</h1>
        <p>Ups, looks like the page your looking for does not exist</p>
        <Link to="/">Lest go back?</Link>
      </section>
    )
  }
}

export default NotFound;
