import React from 'react';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Content from '../components/404-content/404-content';

export default () => (

  <Layout>
    <SEO 
      title="404" 
      keywords={[`digital`, `agency`, `technology`, `Radium`, `404`]} 
    />
    <Content />
  </Layout>
);
